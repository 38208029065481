.order-summary hr {
    height: 1px;
    border-width: 0;
    color: black;
    background-color: black;
}

.item-row {
    display: flex;
    flex-wrap: nowrap;
    gap: 1rem;
}

.course-info {
    flex: 1 1 0;
}

.program-description {
    max-height: 10rem;
}
