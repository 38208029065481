.col-cart-summary {
    display: flex;
    flex-direction: column;
    gap: 3rem;

    @include media-breakpoint-up(md) {
        border-right: solid 1px $border-color;
    }

    @include media-breakpoint-down(sm) {
        margin-bottom: 3rem;
    }
}

.list-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.product-line-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;

    @include media-breakpoint-only(md) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.line-item-img {
    max-width: 30%;
}

.product-thumbnail {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.apply-coupon-label {
    display: block;
    font-weight: 400;
    font-size: 0.75rem;
}

.remove-coupon-btn-link {
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
}

.payment-button {
    background-color: $white;
    padding: 0.5rem;
    height: 2.75rem;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    border: solid 1px $border-color;
    border-radius: 0;

    &[disabled] {
      cursor: auto;
      filter: grayscale(100%);
      border: solid 1px #F2F0EF !important;
    }

    &:hover {
        background-color: $white !important;
        border: solid 1px $border-color !important;

        &[disabled] {
          border: solid 1px #F2F0EF !important;
        }
    }

    &.active{
        border: solid 1px $border-color !important;
        background-color: $white !important;
        border-bottom: solid 0.1875rem $primary !important;
    }

    img {
        border: none;
    }
}

.paypal-button {
    height: 44px;
    border-radius: 0;
  }
