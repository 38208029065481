.logo {
    box-sizing: initial;
    display: block;
    height: 1.75rem;
    padding: 1rem 0;
    position: relative;
    top: -0.05em;
}

.logo-div {
    border-bottom: 1px solid $border-color;
}
